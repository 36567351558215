import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllAcademicLevels = () => {
  return ApiService.get("academic_levels");
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getAcademicLevel = (id) => {
  return ApiService.get("academic_levels", id);
};

/**
 * POST request to create a new academic_level
 * @param payload
 * @returns {*}
 */
export const createAcademicLevel = (payload) => {
  return ApiService.post("academic_levels", payload);
};

/**
 * PUT request to update a specific academic_level
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateAcademicLevel = (id, payload) => {
  return ApiService.update("academic_levels", id, payload);
};

/**
 * DELETE request to delete the specified academic_level
 * @param id
 * @returns {*}
 */
export const deleteAcademicLevel = (id) => {
  return ApiService.delete(`academic_levels/${id}`);
};

/**
 * GET request to change status of the specified academic_level
 * @param id
 * @returns {*}
 */
export const changeStatusAcademicLevel = (id) => {
  return ApiService.get("academic_levels/status", id);
};

export default {
  getAllAcademicLevels,
  getAcademicLevel,
  createAcademicLevel,
  updateAcademicLevel,
  deleteAcademicLevel,
  changeStatusAcademicLevel,
};
