<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left>
        mdi-arrow-left
      </v-icon>
      Regresar a reportes
    </v-btn>
    <div class="card card-custom gutter-b">
      <!-- begin::Page header and title -->
      <div class="card-header flex-nowrap border-0 pt-6 pb-0">
        <div class="card-title">
          <div class="card-label">
            <h3>
              Generación de reporte
              <span class="d-block text-muted pt-2 font-size-sm"
                >Reporte de conteo de llegadas tardes</span
              >
            </h3>
          </div>
        </div>
      </div>
      <!-- end::Page header and title -->

      <div class="card-body px-1 px-sm-5">
        <v-container>
          <v-row>
            <v-col cols="12">
              <!-- begin::filters for report generation-->
              <v-card class="elevation-2 mb-5">
                <p class="text-h5 ma-0 pt-4 pl-4 font-weight-medium">
                  Parámetros
                </p>
                <v-divider></v-divider>
                <!-- begin::Filters combos -->
                <v-container class="pa-4">
                  <v-row class="align-items-center mb-5">
                    <!-- begin::select academic level filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <v-select
                          label="Nivel académico"
                          required
                          rounded
                          filled
                          :loading="academicLevels.isLoading"
                          hide-details
                          :items="academicLevels.data"
                          item-text="name"
                          item-value="id"
                          v-model="parameters.academicLevelId"
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip color="primary" small v-if="index === 0">
                              <span>{{ item.name }}</span>
                            </v-chip>
                          </template>
                        </v-select>
                      </div>
                    </v-col>

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <v-menu
                          v-model="date1"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="parameters.startDate"
                              label="Fecha de inicio"
                              prepend-inner-icon="mdi-calendar"
                              required
                              rounded
                              filled
                              hide-details
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="parameters.startDate"
                            @input="date1 = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>
                    <!-- end::select section/group filter-->

                    <!-- begin::select section/group filter-->
                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <v-menu
                          v-model="date2"
                          :close-on-content-click="false"
                          :nudge-right="40"
                          transition="scale-transition"
                          offset-y
                          min-width="auto"
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="parameters.endDate"
                              label="Fecha de inicio"
                              prepend-inner-icon="mdi-calendar"
                              required
                              rounded
                              filled
                              hide-details
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            v-model="parameters.endDate"
                            @input="date2 = false"
                          ></v-date-picker>
                        </v-menu>
                      </div>
                    </v-col>

                    <v-col cols="12" sm="6" lg="4" xl="3" class="my-2 my-md-0">
                      <div class="d-flex align-items-center">
                        <v-text-field
                          v-model="parameters.amount"
                          label="Recurrencia"
                          required
                          rounded
                          filled
                          hide-details
                          type="number"
                        ></v-text-field>
                      </div>
                    </v-col>
                    <!-- end::select section/group filter-->
                  </v-row>
                </v-container>
                <!-- end::Filters combos -->
              </v-card>
              <!-- end::filters for report generation-->

              <!-- begin::cards with generator button for each report -->
              <template>
                <v-row>
                  <!-- begin::excel with students scores col-container-->
                  <v-col cols="12" sm="8" md="8" lg="6" xl="4">
                    <!-- begin::excel with students scores-->
                    <v-card outlined>
                      <!-- begin::card body -->
                      <v-list-item three-line>
                        <v-list-item-content>
                          <div class="text-overline mb-4">
                            estudiantes
                          </div>
                          <v-list-item-title class="text-h5 mb-1">
                            Listado de estudiantes
                          </v-list-item-title>
                          <v-list-item-subtitle
                            >Estudiantes con
                            llegadas tardes.</v-list-item-subtitle
                          >
                        </v-list-item-content>

                        <v-list-item-avatar
                          class="rounded"
                          tile
                          icon
                          size="80"
                          color="success"
                        >
                          <v-icon x-large color="white" class="ml-n1"
                            >mdi-microsoft-excel</v-icon
                          ></v-list-item-avatar
                        >
                      </v-list-item>
                      <!-- end::card body -->

                      <!-- begin:: card button for report generation -->
                      <v-card-actions>
                        <v-btn
                          :loading="generateReportLoading"
                          :disabled="!canGenerateReport"
                          outlined
                          rounded
                          text
                          @click="downloadReport(true)"
                          target="_blank"
                        >
                          Generar reporte
                        </v-btn>
                      </v-card-actions>
                      <!-- end:: card button for report generation -->
                    </v-card>
                  </v-col>
                  <!-- end::excel with students scores col-container-->
                </v-row>
              </template>
              <!-- end::cards with generator button for each report -->
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import academicLevelRepository from "@/repositories/academicLevelRepository";
import axios from "axios";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "ImproveCodeReports",
  title: "Códigos a mejorar | GE ITR",
  data() {
    return {
      date1: false,
      date2: false,
      parameters: {
        academicLevelId: null,
        startDate: null,
        endDate: null,
        amount: null,
      },
      academicLevels: {
        data: [],
        isLoading: false,
      },
      generateReportLoading: false,
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Reportes disciplinarios", route: "discipline_reports" },
      { title: "Llegadas tardes", route: "late_arrival_report" },
    ]);
    this.loadAcademicLevels();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    loadAcademicLevels() {
      this.academicLevels.isLoading = true;
      academicLevelRepository
        .getAllAcademicLevels()
        .then(({ data }) => {
          this.academicLevels.data = data;
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        })
        .finally(() => {
          this.academicLevels.isLoading = false;
        });
    },
    async downloadReport(isForDownload) {
      const { academicLevelId, startDate, endDate, amount } = this.parameters;
      axios({
        url: `${this.DP_URL}student-class/late-arrival-report/academic-level/${academicLevelId}/start-date/${startDate}/end-date/${endDate}/amount/${amount}`,
        method: "GET",
        headers: { Authorization: `${JwtService.getToken()}` },
        responseType: "blob",
      })
        .then((response) => {
          if (isForDownload) {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              `REPORTE DE LLEGADAS TARDE ${this.parameters.startDate} - ${this.parameters.endDate}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return;
          }
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf;charset=utf-8" })
          );
          window.open(url, "_blank");
        })
        .catch((e) => {
          console.log(e);
          this.fireToast({
            title: "Ocurrió un error al descargar el reporte",
          });
        })
        .finally(() => {
          this.generateReportLoading = false;
        });
    },
  },
  computed: {
    DP_URL() {
      let baseURL;
      if (process.env.APP_ENV === "production") {
        baseURL = process.env.DP_API_URL;
      } else if (process.env.APP_ENV === "development") {
        baseURL = process.env.DP_DEV_API_URL;
      } else {
        baseURL = process.env.DP_LOCAL_API_URL;
      }
      return baseURL;
    },
    canGenerateReport() {
      return (
        !!this.parameters.academicLevelId &&
        !!this.parameters.startDate &&
        !!this.parameters.endDate &&
        !!this.parameters.amount
      );
    },
  },
};
</script>

<style></style>
